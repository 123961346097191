import type { AxiosRequestConfig } from "axios";
import axios from "axios";

import { config } from "shared/data";

import type { FollowPayload, FollowPayloadMessageItem, GetUserFollowList } from "./types";
import { generateNewList } from "./utils";

export const axiosOptions: AxiosRequestConfig = {
  withCredentials: true,
};

export const fetchList = async () => {
  const url = new URL(`${config.general.userFollowHost}/user-follow-list-v2`);
  const resp = await axios.get<GetUserFollowList>(url.toString(), {
    ...axiosOptions,
    params: {
      detail: false,
    },
  });
  return resp?.data?.userFollowList;
};

export const create = async (
  previousFollowList: GetUserFollowList["userFollowList"] | undefined,
  payload: FollowPayload,
) => {
  const url = new URL(`${config.general.userFollowHost}/follow`);

  const messageItem: FollowPayloadMessageItem = {
    ...payload,
    type: "sections_topics_authors",
  };

  await axios.post(
    url.toString(),
    {
      messageItem,
    },
    axiosOptions,
  );
  return generateNewList(previousFollowList, payload);
};
